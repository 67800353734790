<template>
  <v-snackbar
    :model-value="modelValue"
    v-bind="safeAttrs"
    @update:model-value="$emit('update:modelValue')"
  >
    <slot name="default">
      <!--injected message goes here-->
    </slot>

    <template #actions>
      <slot name="actions">
        <!--injected actions goes here-->
      </slot>
    </template>
  </v-snackbar>
</template>

<script>
import { defaults } from 'lodash-es';

export default defineComponent({
  name: 'MtfSnackbar',
  inheritAttrs: true,
  customOptions: {},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  computed: {
    safeAttrs() {
      return defaults(
        { ...this.$attrs },
        {
          transition: 'slide-x-reverse-transition',
          timeout: this.$attrs?.persistent ? -1 : this.$attrs.timeout,
          location: 'top right',
          origin: 'overlap',
          variant: 'elevated',
          vertical: true
        }
      );
    }
  }
});
</script>
